<template>

    <div class="delivery">

        <div v-if="shipping" class="shipping">
            {{ $shop.delivery.getDeliveryTitle(shipping) }} <br/>
            <ShippingPriceCollapse :delivery="shipping"></ShippingPriceCollapse>
        </div>
                        
    </div>
       
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import ShippingPriceCollapse from '../delivery/ShippingPriceCollapse.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
    components: {
        ShippingPriceCollapse
    },
    computed: {},
})
export default class Delivery extends Vue {

    shipping:any = null;

    created() {
        if(this.$shop_methodOfDeliveries_shipping.length >  0) {
            this.shipping = this.$shop_methodOfDeliveries_shipping[0];
        }
    }    

    mounted() {
    }

}

</script>