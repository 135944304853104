<template>
  <section class="shop-information content">

    <h1 class="visually-hidden">{{$t('shop-information.title', [$shop_shop.name])}}</h1>
    
    <div class="content-wrap">

      <div class="container clearfix">

        <div class="row">

          <div class="col-md-auto details">

            <h2>{{$t('shop-information.contact.title')}}</h2>

            <div class="contact">
              <address>
                <strong>{{$shop_shop.company.address.name ? $shop_shop.company.address.name : $shop_shop.company.name}}</strong><br>
                {{$shop_shop.company.address.addressLines[0]}}<br>
                {{$shop_shop.company.address.postalCode}} {{$shop_shop.company.address.city}}<br>
              </address>
              <div>
                <span v-if="$shop_shop.company.phones"><strong>{{$t('shop.shop.info.phone')}}</strong> {{$shop_shop.company.phones[0].formatted}}<br/></span>
                <span v-if="$shop_shop.company.emails"><strong>{{$t('shop.shop.info.email')}}</strong> {{$shop_shop.company.emails[0].email}}<br/></span>
                <span v-if="$shop_shop.company.web"><strong>{{$t('shop.shop.info.web')}}</strong> <a :href="$shop_shop.company.web" target="_blank">{{$shop_shop.company.web}}</a></span>
              </div>
            </div>

            <div v-if="$shop_shop.company.socialMedias" class="social-medias clearfix">
              <a v-if="$shop_shop.company.socialMedias.facebook" :href="'https://www.facebook.com/'+$shop_shop.company.socialMedias.facebook" target="_blank" class="social-icon  si-small si-text-color si-facebook">
                <i class="icon-facebook"></i>
                <i class="icon-facebook"></i>
              </a>
              <a v-if="$shop_shop.company.socialMedias.instagram" :href="'https://www.instagram.com/'+$shop_shop.company.socialMedias.instagram" target="_blank" class="social-icon si-small si-text-color si-instagram">
                <i class="icon-instagram"></i>
                <i class="icon-instagram"></i>
              </a>
            </div>

            <div class="identifiers clearfix">
              <span v-if="$shop_shop.company.identifiers && $shop_shop.company.identifiers.siret">
                <strong>{{$t('shop.shop.info.identifiers.siret')}}</strong> {{$shop_shop.company.identifiers.siret}}<br/>
              </span>
              <span v-if="$shop_shop.company.vatNumber">
                <strong>{{$t('shop.shop.info.vatNumber')}}</strong> {{$shop_shop.company.vatNumber}}<br/>
              </span>
            </div>

          </div>

          <div class="col-md map-container">
            <div class="map" ref="map"></div>
          </div>

        </div>

        <div class="row" v-if="false">

          <div class="col-lg-6">

            <h2>{{$t('shop-information.deliveries.title')}}</h2>

            <Delivery></Delivery>

          </div>

          <div class="col-lg-6">

            <h2>{{$t('shop-information.payments.title')}}</h2>

            <Payment></Payment>

          </div>
        </div>

        

			</div>

    </div>

    <StickyCart/>

  </section><!-- #content end -->

</template>

<style>



.shop-information h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.shop-information .row:first-child {
  margin-top:0px;
}

.shop-information .row {
  margin-top:40px;
}

.shop-information .map {
  min-height:200px;
  height:100%;
}

@media (max-width: 767.98px) {
  .shop-information .details .contact {
    display: flex;
    flex-wrap: wrap;
  }

  .shop-information .details .contact address,
  .shop-information .details .contact > div {
    flex: 1 0 auto;
  }

  .shop-information .map {
    margin-top:20px;
  }
}

.identifiers, .social-medias {
  margin-top:20px;
}
</style>


<script lang="ts">
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import Delivery from '../../panels/shop/shop-information/Delivery.vue';
import Payment from '../../panels/shop/shop-information/Payment.vue';
import * as gmap from '@fwk-client/utils/gmap';
import { getPath } from '@igotweb-node-api-utils/misc';

import StickyCart from '../../cms/canvas/shop/StickyCart.vue'

@Component({
  components: {
    StickyCart,
    Delivery,
    Payment
  }
})
export default class Information extends mixins(GenericPage) {

  destroyed() {}

  mounted() {
    // We check if we can show the map
    if(this.$shop_shop && this.$shop_shop.company && 
        this.$shop_shop.company.address && 
        this.$shop_shop.company.address.lat && 
        this.$shop_shop.company.address.lng) {
      this.showMap();
    }
  }

  showMap() {
    // We need to get the apiKey
    var apiKey = getPath(this.$store.state, "server.configuration.fwk.map.gmap.id");
    // We create the map
    gmap.createMap(this.$refs.map as HTMLElement, apiKey, {
			mapTypeId: "roadmap",
			zoom: 17,
      center: {lat:this.$shop_shop.company.address.lat, lng:this.$shop_shop.company.address.lng},
      styles : [
        // We hide other business point of interests
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        }
      ],
    })
    .then((map) => {
      var marker = new google.maps.Marker({
          position: {lat:this.$shop_shop.company.address.lat, lng:this.$shop_shop.company.address.lng},
          label : {
            text:this.$shop_shop.name
          },
          animation: google.maps.Animation.DROP,
          map: map,
          icon : {
            url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png',
            size: new google.maps.Size(27, 43),
            scaledSize: new google.maps.Size(27, 43),
            anchor:new google.maps.Point(14, 43),
            labelOrigin: new google.maps.Point(14, 43+10) // Label origin is center of the text
          }
        })
      var info = new google.maps.InfoWindow({
        content: '<div style="width: 300px;"><h4 style="margin-bottom: 8px;">Hi, we\'re <span>Envato</span></h4><p class="mb-0">Our mission is to help people to <strong>earn</strong> and to <strong>learn</strong> online. We operate <strong>marketplaces</strong> where hundreds of thousands of people buy and sell digital goods every day, and a network of educational blogs where millions learn <strong>creative skills</strong>.</p></div>',
        position: {lat:this.$shop_shop.company.address.lat, lng:this.$shop_shop.company.address.lng},
      })
      marker.addListener('click', function() {
        console.log("gmap marker click")
        // info.open(map);
      })
    })
    .catch((error) => {
      console.log(error);
    });
  }




	
  
}
</script>