<template>

    <div class="payment">

        <div v-if="card" class="card">
            
        </div>
                        
    </div>
       
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
    components: {
        
    },
    computed: {},
})
export default class Payment extends Vue {

    card:any = null;

    created() {
        if(this.$shop_methodOfPayments_card.length >  0) {
            this.card = this.$shop_methodOfPayments_card[0];
        }
    }    

    mounted() {
    }

}

</script>